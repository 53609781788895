import Txt from '@componentPrimitives/Txt';
import CtaButton from '@components/CtaButton';
import EntryMeta from '@components/EntryMeta';
import { Entry } from '@lib/features/entries';
import { useEvent } from '@lib/hooks';
import { useView } from '@lib/store';
import { fmtCategoryNames } from '@lib/utils/format';
import { maybeGet } from '@liquorice/allsorts-craftcms-nextjs';
import { Stack } from '@mui/material';
import { useTranslations } from 'next-intl';
import EventPerformances from './EventPerformances';

export const EventLayoutSidebar = () => {
  const t = useTranslations('common');
  const tEvent = useTranslations('event');
  const view = useView();
  const {
    buyTicketsLink,
    eventMeta: _eventMeta,
    eventLocationSingle,
    ageGroup,
    eventAk,
    eventUsesPerformances,
    eventPerformances,
    ...item
  } = useEvent(view as Entry<'event'>);
  const customBookingLink = maybeGet(item, 'linkCta');

  const locationStr = eventLocationSingle
    ? `${eventLocationSingle.title} ${
        eventLocationSingle.locationMapRef
          ? ` ${tEvent('mapRef', { ref: eventLocationSingle.locationMapRef })}`
          : ''
      }`
    : null;

  const meta = [
    ...(eventUsesPerformances
      ? [
          {
            label: t('datesTimes'),
            value: (
              <EventPerformances
                eventAk={eventAk}
                description={item.dateDescription}
                items={eventPerformances}
              />
            ),
          },
        ]
      : [
          {
            label: t('date'),
            value: [item.eventDateFormatted, item.dateDescription],
          },
          {
            label: t('time'),
            value: item.eventTimesFormatted?.length
              ? item.eventTimesFormatted.map((v, i) => (
                  <Txt key={i} variant="body" cx={{ mT: 'none' }}>
                    {v}
                  </Txt>
                ))
              : null,
          },
        ]),
    {
      label: t('location'),
      value: [locationStr, item.locationDescription].filter(Boolean),
    },
    {
      label: tEvent('ageGroup'),
      value: fmtCategoryNames(ageGroup),
    },
    {
      label: t('price'),
      value: [item.showTicketStr ? item.eventTicketString : null, item.priceDescription].filter(
        Boolean
      ),
    },
  ];

  const slotAfter = buyTicketsLink && (
    <Stack flexDirection="row" justifyContent="flex-start">
      <CtaButton target="_blank" {...(customBookingLink || buyTicketsLink)} />
    </Stack>
  );

  return <EntryMeta metaValues={meta} slotAfter={slotAfter} />;
};
