import Txt, { TxtProps } from '@componentPrimitives/Txt';
import { EventEntryPerformance } from '@lib/hooks';
import { useNextHydrated } from '@lib/utils/createToggleStore';
import { fmtDateRange, fmtTimeRange } from '@lib/utils/format';
import { Stack } from '@mui/material';

export interface EventPerformanceProps extends TxtProps {
  data?: EventEntryPerformance | null;
}

const EventPerformance = ({ data, ...props }: EventPerformanceProps) => {
  const h = useNextHydrated();

  if (!data || !h) return null;

  const { dateStart: timestampStart, dateEnd: timestampEnd } = data;

  const dateStr = fmtDateRange(timestampStart, timestampEnd);
  const timeStr = fmtTimeRange(timestampStart, timestampEnd);

  return (
    <Txt variant="body" cx={{ mT: 'none' }} {...props}>
      {dateStr}
      <br />
      {timeStr}
    </Txt>
  );
};

export interface EventPerformancesProps {
  eventAk?: string | null;
  description?: string;
  items?: EventEntryPerformance[];
}

const EventPerformances = ({ items, /* eventAk, */ description }: EventPerformancesProps) => {
  // const { loading, items } = useEventPerformances(eventAk);
  // {loading && (
  //   <Stack /* alignItems="center" */ mb={4}>
  //     <CircularProgress color="primary" size={30} />
  //   </Stack>
  // )}

  return (
    <Stack>
      <Txt variant="body" cx={{ mB: 'sm' }}>
        {description}
      </Txt>

      {items?.map((v, i) => (
        <EventPerformance key={i} data={v} />
      ))}
    </Stack>
  );
};

export default EventPerformances;
